.ws-root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .btn-back-to-workspace {
    display: flex;
    min-height: 32px;
    min-width: 32px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 0;
    border: none;
    text-decoration: none;
    border-radius: 50%;

    &:focus {
      box-shadow: 0 0 0 0.1rem #fff;
    }

    .back-arrow-icon {
      cursor: pointer;
      font-size: 28px;
      color: #f3f3f3;
    }
  }
}

.signout-menu {
  min-width: 100px !important;
  width: 100px !important;
}

.nav-resource-tab-links {
  padding-top: 8px;
}
